import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT, CUSTOMER_VIEW_CUSTOMERS_LIST } from '../../Constants';
import { CUSTOMER_VIEW_CUSTOMER, CUSTOMER_VIEW_BLOCK, CUSTOMER_VIEW_WATCH } from '../../Constants';
import { Backdrop, CircularProgress} from '@mui/material';
import { showErrorDialog } from "../../features/errorDialog";
import WatchSystem from '../Customer/WatchSystem';
import cookie from "js-cookie";
import '../../styles.css';
import CustomersGridWrapper from '../Customer/GridWrapper';
import Customer from '../Customer/Customer';
import CustomerBlockCategory from '../Customer/BlockCategory';
import {handleApiErrorMessage} from "../../Helper";
import InnerDialog from "../InnerDialog";

export default function CustomersManagement(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customerView, setCustomerView] = useState(CUSTOMER_VIEW_CUSTOMERS_LIST);
  const initalSelectedCustomerValue = {
    id: null,
    name: null,
    phone: null,
    update_date: null
  };
  const [selectedCustomer, setSelectedCustomer] = useState(initalSelectedCustomerValue);
  const initialLine = {
    id: null,
    number: "",
    use_name: ""
  };
  const [selectedLine, setSelectedLine] = useState(initialLine);
  const [selectedCustomerToDelete, setSelectedCustomerToDelete] = useState(initalSelectedCustomerValue);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [deletionNote, setDeletionNote] = useState('');
  const [dialogLoaderVisible, setDialogLoaderVisible] = useState(false);
  const [missingDeletionNote, setMissingDeletionNote] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const DEAFULT_PAGE_SIZE = 5;
  const [pageSize, setPageSize] = useState(DEAFULT_PAGE_SIZE);
  const initalSearchParameterValue = {
    field: 'name',
    value: ''
  };
  const [searchParameter, setSearchParameter] = useState(initalSearchParameterValue);
  
  useEffect(() => {
    if(loading){
      return () => {};
    }
    refreshCustomersData();
  }, [page, pageSize, searchParameter]);

  const changeSearchParameter = (e, type) => {
    let currentSearchParameter = {
      field: searchParameter.field,
      value: searchParameter.value
    };
    
    currentSearchParameter[type] = e.target.value;
    setSearchParameter(currentSearchParameter);
    setPage(0);
    setPageSize(DEAFULT_PAGE_SIZE);
  };

  const handleDeleteCustomer = (cellValues) => {
    setSelectedCustomerToDelete({
      id: cellValues.id,
      name: cellValues.name,
      phone: cellValues.phone,
      update_date: cellValues.update_date
    });
    setDeleteDialogIsOpen(true);
  };

  const resetSelectedCustomerToDelete = () => {
    setSelectedCustomerToDelete(initalSelectedCustomerValue);
  }

  const refreshCustomersData = (pageToStart = null) => {
    if(pageToStart === null){
      pageToStart = page;
    }
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let  headers = {
      token: cookie.get('token')
    }

    axios.post(API_URL + '/getCustomers', {start: pageToStart*pageSize, limit: pageSize, searchBy: searchParameter.field, search: searchParameter.value}, {headers})
    .then(
      ({ data }) => {
        if(data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("customers"))){
          setRows(data.customers);
          setRowCount(data.totalCount);
          setLoading(false);
        }else{
          if((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))){
            errorMessage = data["message"];
          }
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        }
      }).catch((error) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
    });
  };

  const handleDeleteRowSubmit = async () => {
    if(deletionNote === ""){
      setMissingDeletionNote(true);
      return;
    }
    setMissingDeletionNote(false);

    let errorMessage = t(DEFAULT_ERROR_TEXT);

    setDialogLoaderVisible(true);

    let  headers = {
      token: cookie.get('token')
    }
    
      const data = {
        customer_id: selectedCustomerToDelete.id,
        note: deletionNote
      };

      let apiUrl = API_URL + '/outCustomer';

      axios.post(apiUrl, data, {headers})
      .then(
         (responseData) => {
          if(responseData.data.hasOwnProperty('responseStatus') && responseData.data.responseStatus === API_OK){
            refreshCustomersData();
            resetSelectedCustomerToDelete();
            setDialogLoaderVisible(false);
            setDeleteDialogIsOpen(false);
          }else{
            if(responseData.data.responseStatus === API_EXCEPTION && responseData.data.hasOwnProperty("message")){
              errorMessage = responseData.data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setDialogLoaderVisible(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setDialogLoaderVisible(false);
      });
  };

  const getCustomerPageContent = () => {
    return (customerView === CUSTOMER_VIEW_CUSTOMER) ?
    <Customer 
      isDesktop={props.isDesktop}
      customer={selectedCustomer} 
      setCustomerView={setCustomerView}
      selectedLine={selectedLine}
      setSelectedLine={setSelectedLine}
      resetSelectedLine={() => {setSelectedLine(initialLine)}}
       /> : 
       (customerView === CUSTOMER_VIEW_WATCH ? 
          (
            selectedLine.id !== null ?
            <WatchSystem 
              isDesktop={props.isDesktop} 
              entityType="line" 
              line={selectedLine} 
              customer={selectedCustomer}
              setCustomerView={setCustomerView}
                /> :
            <WatchSystem 
              isDesktop={props.isDesktop}  
              entityType="customer" 
              customer={selectedCustomer}
              setCustomerView={setCustomerView}
                />
          )
          :
          <CustomerBlockCategory 
            isDesktop={props.isDesktop}
            customer={selectedCustomer}
            selectedLine={selectedLine}
            setSelectedLine={setSelectedLine}
            resetSelectedLine={() => {setSelectedLine(initialLine)}}
            setCustomerView={setCustomerView}
            />
       )
    ;
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
    setMissingDeletionNote(false);
  }

  return (
    <>
      {(props.isDesktop ?
      <>
        <CustomersGridWrapper 
        isDesktop={props.isDesktop} 
        setCustomerView={setCustomerView}
        setSelectedCustomer={setSelectedCustomer}
        handleDeleteCustomer={handleDeleteCustomer}
        page={page}
        rows={rows}
        pageSize={pageSize}
        rowCount={rowCount}
        loading={loading}
        setPage={setPage}
        setPageSize={setPageSize}
        changeSearchParameter={changeSearchParameter}
        searchParameter={searchParameter}
        />
        <InnerDialog 
          className='customer-info-dialog'
          open={customerView === CUSTOMER_VIEW_CUSTOMER || customerView === CUSTOMER_VIEW_BLOCK || customerView === CUSTOMER_VIEW_WATCH} 
          onClose={() => {
            setCustomerView(CUSTOMER_VIEW_CUSTOMERS_LIST);
          }} 
        >
          {getCustomerPageContent()}
        </InnerDialog> 
      </>
      :
        (
          (customerView === CUSTOMER_VIEW_CUSTOMERS_LIST) ?
          <CustomersGridWrapper 
            isDesktop={props.isDesktop} 
            setCustomerView={setCustomerView}
            setSelectedCustomer={setSelectedCustomer}
            handleDeleteCustomer={handleDeleteCustomer}
            page={page}
            rows={rows}
            pageSize={pageSize}
            rowCount={rowCount}
            loading={loading}
            setPage={setPage}
            setPageSize={setPageSize}
            changeSearchParameter={changeSearchParameter}
            searchParameter={searchParameter}
            /> :
            getCustomerPageContent()
        )
      )}
      <InnerDialog 
      className="remove-customer-dialog"
      open={deleteDialogIsOpen} onClose={handleCloseDeleteDialog} 
        >
        <div className='remove-customer-popup popup'>
          <div className='title'>
            <div className='text-wrapper'>
              <img src='design-images/alert-yellow.svg' />
              <div className='text'>{t("Remove Customer from the Kehila")}</div>
            </div>
          </div>
          <div className='content'>
            <div className='text'>
              {t('This action will remove the customer {{customer}} permanently rom the kehila.', {customer: selectedCustomerToDelete.name, interpolation: { escapeValue: false }})}
            </div>
            <div className='note-wrapper'>
              <div className='text'>{t('Reason for removal')}</div>
              <input type="text"
                className='underline'
                id="deletion-note" 
                value={deletionNote} onChange={(e) => setDeletionNote(e.target.value)}
                />
              <div className={'field-note'+(missingDeletionNote ? ' error' : '')}>*{t("Required field")}</div>
            </div>
            <div className='controls'>
              <div className='proceed'
                onClick={handleDeleteRowSubmit}
                >
                  {t("Remove")}
              </div>
              <div className='cancel'
                onClick={handleCloseDeleteDialog}
                >
                  {t("Cancel")}
              </div>
            </div>
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={dialogLoaderVisible}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </InnerDialog>
      </>
    );
}
