import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/user";
import { showErrorDialog } from "../../features/errorDialog";
import axios from "axios";
import { API_OK, API_URL, APP_NAME, DEFAULT_ERROR_TITLE, DEFAULT_ERROR_TEXT, API_EXCEPTION, APP_NAME_SIMKEHILOT } from '../../Constants';
import { Button, Typography, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cookie from "js-cookie";
import {handleApiErrorMessage, formatErrorMessage, getCommunityGeneralInfo} from "../../Helper"
import { logout } from "../../features/user";
import { hideErrorDialog } from "../../features/errorDialog";


export function Login() {
    const [state, setState] = useState({ username: "", password: "" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const errorDialog = useSelector((state) => state.errorDialog.value);
    
    const handleErrorDialogClose = () => {
        dispatch(hideErrorDialog());
      };

    useEffect(() => {
        let errorMessage = cookie.get('logout_error');
        if(errorMessage !== "null" && (typeof errorMessage !== 'undefined')){
            const errorMessageToDisplay = formatErrorMessage(errorMessage, t);
            dispatch(showErrorDialog({title : t(DEFAULT_ERROR_TITLE), text: errorMessageToDisplay, page: "login"}));
            cookie.set('logout_error', "null");
        }
      }, []);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        });
    };

    const handleInvalid = (e) => {
        if(e.target.value === ""){
            e.target.setCustomValidity(t("Please fill in the field"));
        }else{
            e.target.setCustomValidity("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errorMessage = t(DEFAULT_ERROR_TEXT);
        const data = {userName: state.username, password: state.password};
    
        try {
            const reply = await axios.post(API_URL + '/login', data);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK
                && reply.data.hasOwnProperty('token')){
                const communityGeneralInfo = await getCommunityGeneralInfo(reply.data.token, t, dispatch, showErrorDialog, logout, navigate);
                document.title = reply.data.webConfig.title;
                dispatch(login({token : reply.data.token, community_name: communityGeneralInfo.name, smsWatchSystemAllow: communityGeneralInfo.smsWatchSystemAllow}));
                navigate("/dashboard");
            }else{
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                  }
                  handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate, "login");
            };
        } catch (e) {
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate, "login");
        }
    };

    return (

            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                >
                <div className="login-page-decoration"
                style={
                    (
                        APP_NAME === APP_NAME_SIMKEHILOT ?
                        {backgroundImage : 'url("design-images/login-background.png")'} : {}
                    )
                }></div>
                <div className="login-form-wrapper">
                    <div className="login-form">
                        <img src={'design-images/'+APP_NAME+'/logo.png'} className='logo' />
                        {(
                            APP_NAME === APP_NAME_SIMKEHILOT ?
                            <div className='main-title'>{t('Community management system')}</div> : ''
                        )}
                        <div className='sub-title'>{t('Welcome back, please insert login details')}</div>
                        <form onSubmit={(e) => handleSubmit(e)} className="white">
                            <Box sx={{mb: 1}} className="input-wrapper">
                                <Typography>{t('Username')}</Typography>
                                <TextField  className="input" size="small" required type="username" id="username" onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} />
                            </Box>
                            <Box sx={{mb: 1}} className="input-wrapper">
                                <Typography>{t('Password')}</Typography>
                                <TextField  className="input" size="small" required type="password" id="password" onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} />
                            </Box>
                            <Button type="submit" variant="contained">{t('Login')}</Button>
                        </form>
                        {(
                            errorDialog.isOpen && errorDialog.page === "login" ?
                            <div className='login-error'>
                                <div className='alert'>!</div>
                                <div className='text'>
                                    <div className='message'>{t("INCORRECT_LOGIN")}</div>
                                    <div>{t("Please contact support at 1599-500-500")}</div>
                                </div>
                                <img src="design-images/close.svg" className='close' onClick={handleErrorDialogClose} />
                            </div>
                            : ''
                        )}
                    </div>
                </div>
                <div className='login-decoration-wrapper'>
                    <div className='login-decoration'>
                        {(
                            APP_NAME === APP_NAME_SIMKEHILOT ? 
                            <>
                                <img src={'design-images/'+APP_NAME+'/logo.png'} className='logo' />
                                <div className='main-title'>{t('Mahadrin speech')}</div>
                            </>
                             :
                             <img src={'design-images/'+APP_NAME+'/logo-for-menu.png'} className='logo' />
                        )}
                        
                    </div>
                </div> 
            </Box>
    );
}