import React from 'react';
import { CUSTOMER_VIEW_CUSTOMERS_LIST } from '../../../Constants';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import LineInfo from './LineInfo';
import MobileDataGrid from '../../MobileDataGrid';

export default function Customer(props) {
  const { t } = useTranslation();

  const columns = [
    { field: 'use_name', headerName: t('Line User Name')},
    { field: 'number', headerName: t('Number')},
    { field: 'create_date', headerName: t('Created at')},
    { field: 'last_call', headerName: t('Last Call')}
  ];

  const renderRowExpansion = (cellValues) =>{
    return <LineInfo 
      line={cellValues} 
      customer={props.customer}
       />;
  }

  return (  
    <div className="customer-information-view"
    >
      <div className='title'>
        <div className='back' onClick={() => {props.setCustomerView(CUSTOMER_VIEW_CUSTOMERS_LIST);}}>
            <img src='design-images/arrow-around.svg' className='arrow-around' />
            <div className='text'>{t("Back to customers management")}</div>
        </div>
        <div className='text main'>{t("Customer Information")}</div>
      </div>
      <div className='customer-info'>
        <div className='item'>{t('Name')} | {props.customer.name}</div>
        <div className='item'>{t('Number')} | {props.customer.phone}</div>
        <div className='item'>{t('Last Updated at')} | {props.customer.update_date}</div>
      </div>
      <MobileDataGrid 
      rowCount={props.rowCount}
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      mainColumn="use_name"
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={(newPage) => {
        props.setPage(newPage);
      }}
      onPageSizeChange={(newPageSize) => {
        props.setPageSize(newPageSize);
      }}
      renderRowExpansion={renderRowExpansion}
      visibleExtraColumn="number"
      expandedRowIds={props.expandedRowIds}
      setExpandedRowIds={props.setExpandedRowIds}
      />
    </div>
  );
}
