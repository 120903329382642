import React, {useState} from 'react';
import {Select, MenuItem} from '@mui/material';
import '../../../styles.css';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_VIEW_BLOCK } from '../../../Constants';
import { CUSTOMER_VIEW_CUSTOMER } from '../../../Constants';
import DesktopDataGrid from '../../DesktopDataGrid';


export default function CustomersGridWrapperDesktop(props) {
  const { t } = useTranslation();

  const columns = [
    { field: 'name', headerName: t('Customer Name')},
    { field: 'phone', headerName: t('Number')},
    { field: 'update_date', headerName: t('Last Updated at')},
    { 
      field: 'view', headerName: '',
      renderCell: (cellValues) => {
        return (
          <div className='button-wrapper'>
            <svg 
            className='show-customer button'
            onClick={(e) => handleViewCustomer(cellValues)}
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
            <path d="M5 15.0011C10.5556 6.99902 19.4444 7.00024 25 15.0011C19.4444 22.9996 10.5556 22.9996 5 15.0011Z" fill="white"/>
            <circle cx="15" cy="15" r="3.25" stroke="#FFC700" strokeWidth="1.5"/>
            </svg>
            <div className='description'>{t("View 2")}</div>
          </div>
        );
      }
    },
    { 
      field: 'delete', headerName: '',
      renderCell: (cellValues) => {
        return (
          <div className='button-wrapper'>
            <svg 
            className='delete-customer button' onClick={() => props.handleDeleteCustomer(cellValues)} 
            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="14.7842" cy="14.5657" rx="14.7842" ry="14.5657" fill="#FFC700"/>
            <path d="M20 10L10 20M20 20L10 10" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div className='description'>{t("Delete 2")}</div>
          </div>
          );
      }
    }
  ];

  const handleViewCustomer = (cellValues) => {
    props.setSelectedCustomer({
      id: cellValues.id,
      name: cellValues.name,
      phone: cellValues.phone,
      update_date: cellValues.update_date
    });
    props.setCustomerView(CUSTOMER_VIEW_CUSTOMER);
  };

  return (  
    <div className="customers-wrapper">
      <div className='title'>{t('Customers connected to the community')}</div>
      <div className='grid-wrapper'>
        <DesktopDataGrid 
          rowCount={props.rowCount}
          rows={props.rows}
          columns={columns}
          loading={props.loading}
          page={props.page}
          pageSize={props.pageSize}
          onPageChange={(newPage) => {
            props.setPage(newPage);
          }}
          onPageSizeChange={(newPageSize) => {
            props.setPageSize(newPageSize);
          }}
          />
        <div className='search-wrapper'>
          <Select
            size="small"
            value={props.searchParameter.field}
            onChange={(e) => props.changeSearchParameter(e, 'field')}
            className="field"
            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          >
            <MenuItem value="name">{t("Customer name")}</MenuItem>
            <MenuItem value="number">{t("Number")}</MenuItem>
            <MenuItem value="lineId">{t("Line ID")}</MenuItem>
            <MenuItem value="lineName">{t("Line Use Name")}</MenuItem>
          </Select>
          <input type="text" className="search" id="search-value" value={props.searchParameter.value} onChange={(e) => props.changeSearchParameter(e, 'value')} />
          <img src="design-images/search.svg" className='button' onClick={(e) => props.changeSearchParameter(e, 'value')} />
        </div>
      </div>
    </div>
  );
}
