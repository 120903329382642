import React from 'react';


export default function InnerDialog(props) {
  return (
    props.open === true ? 
    <div className={'inner-dialog '+props.className}>
      {props.children}
    </div> : ''
  );
}
